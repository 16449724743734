import { accountSearch } from 'AppCore/account/api/search'

export function accountsData(
  $q,
  $http,
  Upload,
  AccountModel,
  collections,
  accountsPoller,
  EventsData,
  session,
  pagination,
  BASE_URL,
  API_VERSION
) {
  'ngInject'

  let service = {
    init: init,
    getAccountEmailSettings: getAccountEmailSettings,
    setAccountEmailSettings: setAccountEmailSettings,
    getNext: getNext,
    getAll: getAll,
    hasAccount: hasAccount,
    fetchAccount: fetchAccount,
    create: create,
    update: update,
    deactivate: deactivate,
    uploadLogo: uploadLogo,
    removeLogo: removeLogo,
    search
  }

  let getAllCache: any = {}

  return service

  function init(filter, options) {
    let params: any = {
      sort: (options && options.sort) || 'name',
      role: (options && options.role) || 'member,employee',
      include: (options && 'include' in options) ? options.include : 'folder'
    }

    if (filter) {
      params.filter = 'name~=' + filter
    }

    return AccountModel.$collection(params)
  }

  function getAccountEmailSettings(accountId) {
    return AccountModel.$new(accountId).emails.$resolve().$asPromise()
  }

  function setAccountEmailSettings(accountId, settings) {
    return $http({
      method: 'PATCH',
      url: BASE_URL + '/accounts/' + accountId + '/emails/tasks',
      headers: {
        Accept: API_VERSION
      },
      data: {
        active: settings.active
      }
    })
  }

  function getNext(results, limit, include) {
    let params = {
      limit: limit || 10,
      page: pagination.nextPage(results),
      include: include
    }

    return results.$fetch(params).$asPromise()
  }

  function getAll(accounts, clearCache) {
    let defer = $q.defer()
    let collection = accounts.$collection()

    if (getAllCacheDataExists() && !clearCache) {
      defer.resolve(getAllCache.data)

      return defer.promise
    }

    return collections.getAll(collection).then(function (data) {
      let userId = session && session.user && session.user.id

      // Check for userId before creating cache
      if (userId) {
        getAllCache = {
          userId: userId,
          data: data
        }
      }

      // return the data for promises
      return data
    })
  }

  function hasAccount(id) {
    let req = {
      method: 'GET',
      url: BASE_URL + '/accounts/' + id,
      headers: {
        Accept: API_VERSION
      },
      ignoreError: true
    }
    let defer = $q.defer()

    $http(req)
      .then(function (response) {
        defer.resolve(response.data)
      })
      .catch(function (rejection) {
        defer.reject(rejection.data)
      })

    return defer.promise
  }

  function fetchAccount(id) {
    return AccountModel.$find(id, {
      include:
        'folder,folder.definitions,states,frequencies,filters,subscription,subscription.reseller,flags,blueprint,addresses,task_states'
    }).$asPromise()
  }

  function create(account) {
    return AccountModel.$build(account).$save().$asPromise().then(createSuccess)
  }

  function createSuccess(account) {
    return accountsPoller.pollForCompletion(account).then(function (createdAccount) {
      EventsData.pushEvent('account_was_created', {
        accountId: createdAccount.id
      })
      return createdAccount
    })
  }

  /**
   * Check if the authors data is cached and that the account the user is on matches that data
   */
  function getAllCacheDataExists() {
    let userId = session && session.user && session.user.id

    return !!getAllCache.data && userId === getAllCache.userId
  }

  function patch(account, propertyNames) {
    return account.$save(propertyNames).$asPromise()
  }

  function update(account, propertyNames) {
    return patch(account, propertyNames)
  }

  function deactivate(account) {
    return patch(account, ['active'])
  }

  function uploadLogo(image, accountId) {
    return Upload.upload({
      url: BASE_URL + '/accounts/' + accountId + '/logos',
      file: image,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function removeLogo(accountId) {
    let req = {
      method: 'DELETE',
      url: BASE_URL + '/accounts/' + accountId + '/logos',
      headers: {
        Accept: API_VERSION
      }
    }

    return $http(req)
  }

  function search(account, query, overrideParams = {}) {
    const defer = $q.defer()
    const defaultParams = {
      limit: 25,
      q: query
    }

    const params = { ...defaultParams, ...overrideParams }

    accountSearch({
      accountId: account.id,
      query: params
    })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((response) => {
        defer.reject(response)
      })

    return defer.promise
  }
}
