export function uploadFileActions($q, uploadsData, viewFileModal, fileHandler, BASE_URL, $sce) {
  'ngInject'

  let service = {
    getValidUpload: getValidUpload,
    viewFile: viewFile,
    downloadFile: downloadFile,
    getFileUrl: getFileUrl
  }
  return service

  ////////////////

  /**
   *
   * @param uploadData
   * @param user - the user who uploaded the file
   */
  function viewFile(uploadData, user) {
    // check the upload has completed fully by checking it has a 'view' URL
    if (uploadData && uploadData.view) {
      getValidUpload(uploadData)
        .then(function (upload) {
          viewFileModal.openModal(upload, user, downloadFile)
        })
        .catch(function () {})
    }
  }

  function getFileUrl(url) {
    let destinationUrl = ''

    if (appConfig.IS_LOCAL) {
      return BASE_URL + url
    } else {
      destinationUrl = `/api` + url;
    }
    
    return $sce.trustAsResourceUrl(destinationUrl)
  }

  function downloadFile(uploadData) {
    getValidUpload(uploadData)
      .then(function (upload) {
        fileHandler.download(upload.download)
      })
      .catch(function () {})
  }

  function getValidUpload(upload) {
    let defer = $q.defer()

    if (upload.$expires > Math.floor(Date.now() / 1000)) {
      defer.resolve(upload)
      return defer.promise
    }

    return uploadsData.fetch(upload)
  }
}
